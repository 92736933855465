<template>
  <div class="Calculator">
    <div class="Calculator_h">
      <div class="Calculator_bac"></div>
      <div class="Calculator_title">
        <h3>薪税计算器</h3>
        <!-- <p>智能分配，为您节税</p> -->
      </div>
      <div class="Calculator_box">
        <div class="Calculator_content">
          <div class="Calculator_top">
            <p>税前月薪（元）</p>
            <div class="Calculator_top_money">
              <span class="unit_lf">￥</span>
              <van-field
                v-model="number2"
                input-align="right"
                :type="inputType"
                maxlength="13"
                placeholder="请输入"
                @blur="onBlur"
                @focus="onFocus"
              />
              <span class="unit_ri">元/月</span>
            </div>
            <p class="annotation">
              “税前月薪”请填写全部工资，包括不限于工资+奖金+绩效”
            </p>
          </div>
          <div class="Calculator_main">
            <div class="Calculator_main_box">
              <van-field
                v-model="socialSecurityVal"
                readonly
                label="社保缴纳"
                placeholder="￥0.00"
                type="number"
                input-align="right"
              >
                <template #button>
                  <div class="revise" @click="reviseClick('socialSecurity')">
                    修改
                  </div>
                </template>
              </van-field>
            </div>
            <div class="Calculator_main_box">
              <van-field
                v-model="providentFundVal"
                readonly
                label="公积金缴纳"
                placeholder="￥0.00"
                type="number"
                input-align="right"
              >
                <template #button>
                  <div class="revise" @click="reviseClick('providentFund')">
                    修改
                  </div>
                </template>
              </van-field>
            </div>

            <div class="Calculator_main_box">
              <van-field
                      v-model="specialVal"
                      readonly
                      label="个人专项扣除"
                      placeholder="￥0.00"
                      type="number"
                      input-align="right"
              >
                <template #button>
                  <div class="revise" @click="reviseClick('special')">修改</div>
                </template>
              </van-field>
            </div>
            <div class="Calculator_main_box">
              <van-field
                      v-model="personalElderlyTotalVal"
                      type="number"
                      input-align="right"
                      clearable
                      label="个人养老金扣除"
                      placeholder="请输入"
              >
              </van-field>
            </div>
          </div>
          <div class="Calculator_bottom">
            <div class="remark">
              <p>当前版范围为北京市</p>
              <p>本次计算结果仅限参考</p>
            </div>
            <van-button type="info" @click="reviseClick('compute')"
              >开始计算</van-button
            >
          </div>
        </div>
      </div>
    </div>
    <copyrightIp />
  </div>
</template>
<script>
import { Field, Button, Dialog } from "vant";
import copyrightIp from "@/components/copyrightIp";
import {
  findAllTSocialSecurity,
  findAllTAccumulationFund,
  getEquertyCalculatorGarden,
} from "@/api/Calculator";
import { findUserIntegralGarden } from "@/api/SignIn";
export default {
  components: {
    [Field.name]: Field,
    [Button.name]: Button,
    [Dialog.Component.name]: Dialog.Component,
    copyrightIp,
  },
  beforeDestroy() {
    // sessionStorage.removeItem("socialSecurity");
    sessionStorage.setItem("number", this.number);
    sessionStorage.setItem("socialSecurity", this.socialSecurityVal);
    sessionStorage.setItem("socialSecurityUnitVal", this.socialSecurityUnitVal);
    sessionStorage.setItem("providentFund", this.providentFundVal);
    sessionStorage.setItem("providentFundUnitVal", this.providentFundUnitVal);
    sessionStorage.setItem("personalElderlyTotalVal", this.personalElderlyTotalVal);
  },
  data() {
    return {
      inputType: "number",
      number: "",
      number2: "",
      socialSecurityVal: "", //社保
      socialSecurityUnitVal: "", //社保（公司）
      socialSecurityValData: null,
      providentFundVal: "", // 公积金
      providentFundUnitVal: "", // 公积金（公司）
      providentFundValData: null, // 公积金
      specialVal: "", // 个人专项扣除
      personalElderlyTotalVal: "",//个人养老金
      moonGrade: "", // 计算器等级
    };
  },
  created() {},
  mounted() {
    this.socialSecurityVal = sessionStorage.getItem("socialSecurity") || "";
    this.socialSecurityUnitVal = sessionStorage.getItem("socialSecurityUnitVal") || "";
    this.providentFundVal = sessionStorage.getItem("providentFund") || "";
    this.providentFundUnitVal =  sessionStorage.getItem("providentFundUnitVal") || "";
    this.specialVal = sessionStorage.getItem("special") || "";
    this.personalElderlyTotalVal = sessionStorage.getItem("personalElderlyTotalVal") || "";
    // if (this.socialSecurityVal.length) {
    //   this.getFindAllTSocialSecurity();
    // }
    // if (this.providentFundVal.length) {
    //   this.getFindAllTAccumulationFund();
    // }

    this.number = sessionStorage.getItem("number") || "";
    this.number2 = this.toMoney(this.number);
    // 计算机权限接口
    getEquertyCalculatorGarden({ type: 3 }).then((res) => {
      let data = res.data.data;
      if (data) {
        this.moonGrade = Number(data.split("V")[1]);
      } else {
        this.moonGrade = 1;
      }
    });
  },
  methods: {
    onFocus() {
      this.inputType = "number";
      this.number2 = Number(this.number2.replace(/,/g, ""));
    },
    // 输入框失焦事件
    onBlur() {
      this.inputType = "text";
      this.number = Number(this.number2.replace(/,/g, ""));
      this.number2 = this.toMoney(this.number2);
      if (this.socialSecurityValData) {
        this.personalSocialSecurity();
      } else {
        this.getFindAllTSocialSecurity();
      }
      if (this.providentFundValData) {
        this.personalProvidentFund();
      } else {
        this.getFindAllTAccumulationFund();
      }
    },
    toMoney(num) {
      num = num.replace(/,/g, "");
      let money = Number(num);
      // money = money.toFixed(2);

      // money = parseFloat(money);

      money = money.toLocaleString("zh", {
        style: "decimal",
        minimumFractionDigits: 2,
      });
      return money; // 返回的是字符串23,245.12保留2位小数
    },
    // numFilter(val) {
    //   val = val.replace(/,/g, "");
    //   val = Number(val).toLocaleString(); // 3,000
    //   if (val.indexOf(".") == -1) {
    //     val = val + ".00"; //3,000.00
    //   } else if (val.indexOf(".") == val.length - 2) {
    //     val = val + "0";
    //   }
    //   return val;
    // },
    reviseClick(type) {
      this.number = Math.round(this.number * 100) / 100;
      let openId = localStorage.getItem("code") || "";
      if (this.number) {
        switch (type) {
          case "socialSecurity":
            this.$router.push({
              path: "/calculator/socialSecurity",
              query: {
                number: this.number,
              },
            });
            break;
          case "providentFund":
            this.$router.push({
              path: "/calculator/providentFund",
              query: {
                number: this.number,
              },
            });
            break;
            case "special":
                this.$router.push("/calculator/special");
            break;
          case "compute":
            this.$router.push({
                path: "/calculator/compute",
                query: {
                    number: this.number,
                    socialSecurityVal: this.socialSecurityVal || 0,
                    socialSecurityUnitVal: this.socialSecurityUnitVal || 0,
                    providentFundVal: this.providentFundVal || 0,
                    providentFundUnitVal: this.providentFundUnitVal || 0,
                    specialVal: this.specialVal || 0,
                },
            });
            break;
        }
      } else {
        if (localStorage.getItem("status") == 102 && openId) {
          Dialog({ message: "您还未输入税前收入哦" });
          this.number = null;
        } else {
            Dialog({ message: "您还未输入税前收入哦" });
            this.number = null;
        }
      }
    },
    // 个人社保
    personalSocialSecurity() {
      let num = Number(this.number);
      if (num) {
        let min = Number(this.socialSecurityValData.baseLowerLimit);
        let max = Number(this.socialSecurityValData.baseUpperLimit);

        if (num < min) {
          num = min;
        } else if (num > max) {
          num = max;
        }
        this.socialSecurityVal =
          num * this.socialSecurityValData.userAged +
          num * this.socialSecurityValData.userMedicalInsurance +
          Number(this.socialSecurityValData.userMedicalInsuranceValue) +
          num * this.socialSecurityValData.userUnemployment;
        this.socialSecurityVal = Math.round(this.socialSecurityVal * 100) / 100;
        this.socialSecurityUnitVal =
          num * this.socialSecurityValData.companyAged +
          num * this.socialSecurityValData.companyMedicalInsurance +
          num * this.socialSecurityValData.companyUnemployment +
          num * this.socialSecurityValData.companyJobInjury;
        this.socialSecurityUnitVal =
          Math.round(this.socialSecurityUnitVal * 100) / 100;
      }
    },
    // 个人公积金
    personalProvidentFund() {
      let num = Number(this.number);
      if (num) {
        let min = Number(this.providentFundValData.lowBase);
        let max = Number(this.providentFundValData.upperBase);
        if (num < min) {
          num = min;
        } else if (num > max) {
          num = max;
        }

        this.providentFundVal = num * 0.12;
        this.providentFundUnitVal = num * 0.12;
      }
    },
    // 获取社保缴纳比例
    getFindAllTSocialSecurity() {
      findAllTSocialSecurity().then((res) => {
        this.socialSecurityValData = res.data.data[0];
        this.personalSocialSecurity();
      });
    },
    // 获取公积金
    getFindAllTAccumulationFund() {
      findAllTAccumulationFund().then((res) => {
        this.providentFundValData = res.data.data[0];
        this.personalProvidentFund();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.Calculator {
  width: 100%;
  height: 100%;
  position: relative;
  // padding-bottom: 70px;
  .Calculator_h {
    min-height: calc(100% - 44px);
  }
  .Calculator_bac {
    width: 100%;
    height: 250px;
    background-image: url("../../../assets/img/Utilities1_Moon_01.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .Calculator_title {
    padding: 10px 10px 0;
    color: #fff;
    h3 {
      font-size: 20px;
      font-weight: bold;
    }
    p {
      font-size: 12px;
      padding-bottom: 5px;
    }
  }
  .Calculator_box {
    width: 100%;
    padding: 10px;

    .Calculator_content {
      width: 100%;
      padding: 10px;
      background: #fff;
      border-radius: 10px;
      margin-top: 5px;
    }
  }
}
.Calculator_top {
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  padding: 10px;
  font-weight: bold;
  .Calculator_top_money {
    display: flex;
    align-items: center;
    padding: 10px 0;
    .unit_lf {
      font-size: 35px;
    }
    .unit_ri {
      min-width: 38px;
      color: #999999;
    }
    ::v-deep .van-cell {
      padding-left: 3px;
      .van-cell__value {
        font-size: 25px;
      }
      input::-webkit-input-placeholder {
        color: #ccc;
        font-size: 25px;
        font-weight: 100;
        line-height: 80px;
      }
    }
  }
  .annotation {
    font-size: 12px;
    color: #3d8df7;
    font-weight: 100;
  }
}
.Calculator_main {
  width: 100%;
  .Calculator_main_box {
    width: 100%;
    display: flex;
    align-items: center;
    .revise {
      min-width: 30px;
      color: #2a93ff;
    }
    .van-cell {
      padding: 10px 10px;
      border-bottom: 1px solid #f2f2f2;
      white-space: nowrap;
    }
  }
}
.Calculator_bottom {
  padding: 40px 0 15px 0;
  .remark {
    padding-bottom: 15px;
    padding-left: 10px;
    p {
      line-height: 30px;
      padding-left: 8px;
      position: relative;
      &::before {
        content: "*";
        position: absolute;
        top: -2px;
        left: 0;
        color: red;
        font-weight: bold;
        font-size: 12px;
      }
    }
  }
  .van-button {
    width: 100%;
    border-radius: 6px;
  }
}
</style>
